import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../../app/store';
import {
  fetchMemos,
  fetchMemo,
  remoteMemoContentSync,
  fetchRepresentation,
  fetchTag,
  fetchMemoShares,
  remoteMemoShareSync,
  fetchMemoShare,
  fetchMemoContent,
  fetchRunTraining,
  fetchTraining
} from './memoAPI';
import {
  UIMemoShape,
  UIMemoContentShape,
  UIShareShape,
  UIShareStatusShape,
  UIRepresentationListShape,
  UITagListShape,
  FetchResponseShape,
  HttpResponseType,
  MessageType,
  TrainingRequestShape,
  UITrainingListShape
} from '../../../model/MemoModelShapes';

import {
  SYNC_MEMOS_BY_GROUP,
  SYNC_MEMOS,
  SYNC_TRANSFER_MEMOS_BY_GROUP,
  SYNC_MEMO_SHARES,
  SYNC_MEMO_SHARES_BY_GROUP,
  UPDATE_MEMO_TAG,
  UPDATE_MEMO,
  UPDATE_MEMO_SHARE,
  UPDATE_MEMO_CONTENT,
  REFRESH_MEMO,
  UPDATE_MEMO_PROPERTIES,
  CREATE_MEMO,
  UPDATE_MEMO_TRAINING
} from '../../constants/ReduxConst';
import { DEFAULT_GROUP_GUID, retryInterval } from '../../../common/MetaSetting';
import { findOneWMObjectFromAll, getWMObjectsNeedSyncForSource } from '../../../common/Utility';
import { getEmptyMemoContent, getEmptyShare, MemoStatus, SHARE_SETTING_TYPE_MARK, SHARE_TYPE_MARK, SyncResult } from '../../../common/MetaData';
import { saveTagToLocalMapping } from '../search/searchSlice';
import { appendMessageAsync } from '../../../appSlice';

export interface MemoState {
  memos: UIMemoShape[];
  memoContents: UIMemoContentShape[];
  representations: UIRepresentationListShape[];
  memoTags: UITagListShape[];
  memoTrainings: UITrainingListShape[];
  memoShares: UIShareShape[];
  selectedMemoId: string;
  memoContentUpdated: boolean;
  failedMemoUpdateRecords: any[];
  status: 'idle' | 'loading' | 'failed';
  isSyncing: boolean;
  currentMemo: UIMemoShape | undefined;
  currentMemoContent: UIMemoContentShape | undefined;
  currentMemoTags: UITagListShape | undefined;
  currentMemoTrainings: UITrainingListShape | undefined;
  currentEditMemoContent: string;
}

const initialState: MemoState = {
  memos: [],
  memoContents: [],
  representations: [],
  memoTags: [],
  memoTrainings: [],
  memoShares: [],
  selectedMemoId: '',
  memoContentUpdated: false,
  failedMemoUpdateRecords: [],
  status: 'idle',
  isSyncing: false,
  currentMemo: undefined,
  currentMemoContent: undefined,
  currentMemoTags: undefined,
  currentMemoTrainings: undefined,
  currentEditMemoContent: ''
};

const getMemosNeedSyncForLocal = (localMemoList: UIMemoShape[], remoteMemoList: UIMemoShape[]) => {
  var result: UIMemoShape[] = [];
  remoteMemoList.forEach((remoteMemo) => {
    var filteredItems = localMemoList.filter((m) => m.guid === remoteMemo.guid);
    if (filteredItems.length > 1) {
      if (filteredItems[0].modifyTimestamp < remoteMemo.modifyTimestamp) {
        result.push(remoteMemo);
      }
    } else {
      result.push(remoteMemo);
    }
  });
  // console.log("Memo need update for local", result);
  return result;
};

const getMemoSharesNeedSyncForRemote = (localMemoList: UIShareShape[], remoteMemoList: UIShareShape[]) => {
  var result: UIShareShape[] = [];
  localMemoList.forEach((localMemo) => {
    var filteredItems = remoteMemoList.filter((m) => m.guid === localMemo.guid);
    if (filteredItems.length === 0 || (filteredItems.length > 0 && filteredItems[0].modifyTimestamp < localMemo.modifyTimestamp)) {
      result.push(localMemo);
    }
  });
  // console.log("Memo need update for remote", result);
  return result;
};

const getMemoSharesNeedSyncForLocalGroup = (localMemoShareList: UIShareShape[], remoteMemoShareList: UIShareShape[]) => {
  var result: UIShareShape[] = [];
  remoteMemoShareList.forEach((remoteShare) => {
    var filteredItems = localMemoShareList.filter((localShare) => localShare.guid === remoteShare.guid);
    if (filteredItems.length === 0 || (filteredItems.length > 0 && filteredItems[0].modifyTimestamp < remoteShare.modifyTimestamp)) {
      result.push(remoteShare);
    }
  });
  // console.log("Memo need update for local group", result);
  return result;
};

const changeMemoShareFlag = (memos: UIMemoShape[], memo: UIMemoShape, shareSetting: UIShareShape) => {
  var filteredMemos = memos.filter((m) => m.guid !== shareSetting.guid);
  let bShare = shareSetting.isPublic || (shareSetting.targetUserGroupIds && shareSetting.targetUserGroupIds.length > 0) || (shareSetting.targetUserIds && shareSetting.targetUserIds.length > 0);
  var updatedMemos = [
    ...filteredMemos,
    Object.assign({}, memo, {
      hasShare: bShare
    })
  ];
  return updatedMemos;
};

const getMemoContentsNeedSyncForLocalGroup = (localMemoList: UIMemoShape[], localMemoContentList: UIMemoShape[], remoteMemoList: UIMemoShape[]) => {
  var result: UIMemoShape[] = [];
  remoteMemoList.forEach((remoteMemo) => {
    var foundItems = localMemoList.filter((m) => m.guid === remoteMemo.guid);
    if (foundItems.length === 0 || (foundItems.length > 0 && foundItems[0].modifyTimestamp < remoteMemo.modifyTimestamp)) {
      result.push(remoteMemo);
    } else {
      var foundItems = localMemoContentList.filter((mc) => mc.guid === remoteMemo.guid);
      if (foundItems.length === 0) result.push(remoteMemo);
    }
  });
  // console.log("Memo need update for local group", result);
  return result;
};

const getMemosNeedSyncForRemote = (localMemoList: UIMemoShape[], remoteMemoList: UIMemoShape[]) => {
  var result: UIMemoShape[] = [];
  localMemoList.forEach((localMemo) => {
    if (localMemo.stateCode === 0) return;
    var filteredItems = remoteMemoList.filter((m) => m.guid === localMemo.guid);
    if (filteredItems.length === 0 || (filteredItems.length > 0 && filteredItems[0].modifyTimestamp < localMemo.modifyTimestamp)) {
      result.push(localMemo);
    }
  });
  // console.log("Memo need update for remote", result);
  return result;
};

const remoteMemoShareSyncRetry = (oidcFetch: Function, memoShareId: string, thunkAPI: any) => {
  let memoShareLocal = findOneWMObjectFromAll(thunkAPI.getState().memo.memoShares, memoShareId);
  remoteMemoShareSync(oidcFetch, memoShareLocal).then((shareSyncResponse: FetchResponseShape) => {
    if (shareSyncResponse.responseType === HttpResponseType.SUCCESS) {
      shareSyncResponse.content.then((content: any) => {
        if (content.syncResult === -1 && content.syncedShare) {
          thunkAPI.dispatch(saveRemoteMemoShareToLocal(content.syncedShare));
        }
      });
    } else {
      thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: shareSyncResponse.message }));
      setTimeout(() => remoteMemoShareSyncRetry(oidcFetch, memoShareId, thunkAPI), retryInterval);
    }
  });
};

const fetchMemoSharesByGroupRetry = (oidcFetch: Function, groupId: string, thunkAPI: any) => {
  fetchMemoShares(oidcFetch, 0).then((memoShareResponse: FetchResponseShape) => {
    if (memoShareResponse.responseType === HttpResponseType.SUCCESS) {
      // console.log("Local memo shares", state.memo.memoShares);
      // console.log("Remote memo shares", remoteMemoShares);
      memoShareResponse.content.then((remoteMemoShares: any) => {
        let localMemoShares = [...thunkAPI.getState().memo.memoShares];
        var memoSharesLocalNeedSync = getMemoSharesNeedSyncForLocalGroup(
          localMemoShares,
          remoteMemoShares.filter((rms: any) => rms.groupId === groupId)
        );
        memoSharesLocalNeedSync.forEach((memoShareLocal) => {
          fetchMemoShareRetry(oidcFetch, memoShareLocal.guid, thunkAPI);
        });

        var memoSharesRemoteNeedSync = getMemoSharesNeedSyncForRemote(localMemoShares, remoteMemoShares);
        memoSharesRemoteNeedSync.forEach((memoShareLocal) => {
          remoteMemoShareSyncRetry(oidcFetch, memoShareLocal.guid, thunkAPI);
        });
        // console.log("Memo share need update for remote", memoSharesRemoteNeedSync);
      });
    } else {
      thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: memoShareResponse.message }));
      setTimeout(() => fetchMemoSharesByGroupRetry(oidcFetch, groupId, thunkAPI), retryInterval);
    }
  });
};

export const syncMemoShareByGroupAsync = createAsyncThunk<number, any, { state: RootState }>(SYNC_MEMO_SHARES_BY_GROUP, async (param, thunkAPI) => {
  let currentTime = new Date().getTime();
  fetchMemoSharesByGroupRetry(param.oidcFetch, param.group.guid, thunkAPI);

  return currentTime;
});

const syncMemosByGroupRetry = (oidcFetch: Function, groupId: string, thunkAPI: any) => {
  fetchMemos(oidcFetch, 0).then((memosResponse: FetchResponseShape) => {
    if (memosResponse.responseType === HttpResponseType.SUCCESS) {
      memosResponse.content.then((remoteMemos: any) => {
        let localMemos = [...thunkAPI.getState().memo.memos];
        let localMemoContents = [...thunkAPI.getState().memo.memoContents];
        var memosLocalNeedSync = getMemoContentsNeedSyncForLocalGroup(
          localMemos,
          localMemoContents,
          remoteMemos.filter((rm: any) => rm.groupId === groupId)
        );
        memosLocalNeedSync.forEach((memoLocal) => {
          fetchFullMemoRetry(oidcFetch, memoLocal.guid, thunkAPI);
        });

        var memosRemoteNeedSync = getMemosNeedSyncForRemote(localMemos, remoteMemos);
        memosRemoteNeedSync.forEach((memoLocal) => {
          remoteFullMemoSyncRetry(oidcFetch, memoLocal.guid, thunkAPI);
        });
      });
    } else {
      thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: memosResponse.message }));
      setTimeout(() => syncMemosByGroupRetry(oidcFetch, groupId, thunkAPI), retryInterval);
    }
  });
};

export const syncMemoByGroupAsync = createAsyncThunk<number, any, { state: RootState }>(SYNC_MEMOS_BY_GROUP, async (param, thunkAPI) => {
  let currentTime = new Date().getTime();
  syncMemosByGroupRetry(param.oidcFetch, param.group.guid, thunkAPI);

  return currentTime;
});

export const transferToGroupByGroupIdAsync = createAsyncThunk<number, any, { state: RootState }>(SYNC_TRANSFER_MEMOS_BY_GROUP, async (param, thunkAPI) => {
  let currentTime = new Date().getTime();
  let localMemos = [...thunkAPI.getState().memo.memos];
  localMemos
    .filter((lm: any) => lm.groupId === param.groupId)
    .forEach((memo: UIMemoShape) => {
      let memoLocal: UIMemoShape = Object.assign({}, memo, {
        groupId: DEFAULT_GROUP_GUID,
        modifyTimestamp: currentTime,
        updateStatus: MemoStatus.UPDATING
      });
      thunkAPI.dispatch(saveMemoToLocal(memoLocal));
      remoteFullMemoSyncRetry(param.oidcFetch, memoLocal.guid, thunkAPI);
    });

  return currentTime;
});

const fetchMemoShareRetry = (oidcFetch: Function, memoShareId: string, thunkAPI: any) => {
  fetchMemoShare(oidcFetch, memoShareId).then((memoShareResponse: FetchResponseShape) => {
    if (memoShareResponse.responseType === HttpResponseType.SUCCESS) {
      memoShareResponse.content.then((content: any) => {
        thunkAPI.dispatch(saveRemoteMemoShareToLocal(content));
      });
    } else {
      thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: memoShareResponse.message }));
      setTimeout(() => fetchMemoShareRetry(oidcFetch, memoShareId, thunkAPI), retryInterval);
    }
  });
};

const syncAllMemoSharesRetry = (oidcFetch: Function, thunkAPI: any) => {
  let memoShares = [...thunkAPI.getState().memo.memoShares];
  fetchMemoShares(oidcFetch, 0).then((response: FetchResponseShape) => {
    if (response.responseType === HttpResponseType.SUCCESS) {
      // console.log("Local memo shares", thunkAPI.getState().memo.memoShares);
      // console.log("Remote memo shares", remoteMemoShares);
      response.content.then((remoteMemoShares: any) => {
        var memoSharesLocalNeedSync = getWMObjectsNeedSyncForSource(memoShares, remoteMemoShares);
        memoSharesLocalNeedSync.forEach((memoShareLocal) => {
          fetchMemoShareRetry(oidcFetch, memoShareLocal.guid, thunkAPI);
        });

        var memoSharesRemoteNeedSync = getWMObjectsNeedSyncForSource(remoteMemoShares, memoShares);
        memoSharesRemoteNeedSync.forEach((memoShareLocal) => {
          remoteMemoShareSyncRetry(oidcFetch, memoShareLocal.guid, thunkAPI);
        });
        // console.log("Memo share need update for remote", memoSharesRemoteNeedSync);
      });
    } else {
      thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: response.message }));
      setTimeout(() => syncAllMemoSharesRetry(oidcFetch, thunkAPI), retryInterval);
    }
  });
};

const fetchMemoSharesRetry = (oidcFetch: Function, thunkAPI: any) => {
  fetchMemoShares(oidcFetch, 0).then((response: FetchResponseShape) => {
    if (response.responseType === HttpResponseType.SUCCESS) {
      response.content.then((remoteMemoShares: any) => {
        remoteMemoShares.forEach((memoShareLocal: any) => {
          fetchMemoShareRetry(oidcFetch, memoShareLocal.guid, thunkAPI);
        });
      });
    } else {
      thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: response.message }));
      setTimeout(() => fetchMemoSharesRetry(oidcFetch, thunkAPI), retryInterval);
    }
  });
};

export const fetchAllMemoSharesAsync = createAsyncThunk<number, Function, { state: RootState }>(SYNC_MEMO_SHARES, async (oidcFetch, thunkAPI) => {
  let currentTime = new Date().getTime();
  fetchMemoSharesRetry(oidcFetch, thunkAPI);

  return currentTime;
});

const fetchMemoRetry = (oidcFetch: Function, memoId: string, thunkAPI: any) => {
  fetchMemo(oidcFetch, memoId).then((memoResponse: FetchResponseShape) => {
    if (memoResponse.responseType === HttpResponseType.SUCCESS) {
      memoResponse.content.then((content: any) => {
        thunkAPI.dispatch(saveMemoToLocal(Object.assign({}, content, { updateStatus: MemoStatus.UPDATED })));
        fetchTagRetry(oidcFetch, content.guid, thunkAPI);
        fetchTrainingRetry(oidcFetch, content.guid, thunkAPI);
      });
    } else {
      thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: memoResponse.message }));
      setTimeout(() => fetchMemoRetry(oidcFetch, memoId, thunkAPI), retryInterval);
    }
  });
};

const fetchContentRetry = (oidcFetch: Function, memoId: string, thunkAPI: any) => {
  fetchMemoContent(oidcFetch, memoId).then((memoResponse: FetchResponseShape) => {
    if (memoResponse.responseType === HttpResponseType.SUCCESS) {
      memoResponse.content.then((content: any) => {
        thunkAPI.dispatch(saveMemoContentToLocal(content));
      });
    } else {
      thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: memoResponse.message }));
      setTimeout(() => fetchContentRetry(oidcFetch, memoId, thunkAPI), retryInterval);
    }
  });
};

const fetchFullMemoRetry = (oidcFetch: Function, memoId: string, thunkAPI: any) => {
  fetchMemo(oidcFetch, memoId).then((memoResponse: FetchResponseShape) => {
    if (memoResponse.responseType === HttpResponseType.SUCCESS) {
      memoResponse.content.then((content: any) => {
        thunkAPI.dispatch(saveMemoToLocal(Object.assign({}, content, { updateStatus: MemoStatus.UPDATED })));
        fetchContentRetry(oidcFetch, content.guid, thunkAPI);
        fetchTagRetry(oidcFetch, content.guid, thunkAPI);
        fetchTrainingRetry(oidcFetch, content.guid, thunkAPI);
      });
    } else {
      thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: memoResponse.message }));
      setTimeout(() => fetchFullMemoRetry(oidcFetch, memoId, thunkAPI), retryInterval);
    }
  });
};

const fetchMemosRetry = (oidcFetch: Function, thunkAPI: any) => {
  fetchMemos(oidcFetch, 0).then((memosResponse: FetchResponseShape) => {
    if (memosResponse.responseType === HttpResponseType.SUCCESS) {
      memosResponse.content.then((remoteMemos: any) => {
        remoteMemos.forEach((remoteMemo: any) => {
          thunkAPI.dispatch(saveMemoToLocal(Object.assign({}, remoteMemo, { updateStatus: MemoStatus.UPDATED })));
        });
      });
    } else {
      thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: memosResponse.message }));
      setTimeout(() => fetchMemosRetry(oidcFetch, thunkAPI), retryInterval);
    }
  });
};

const fetchMemosWithAttachedContentRetry = (oidcFetch: Function, thunkAPI: any) => {
  fetchMemos(oidcFetch, 0).then((memosResponse: FetchResponseShape) => {
    if (memosResponse.responseType === HttpResponseType.SUCCESS) {
      memosResponse.content.then((remoteMemos: any) => {
        remoteMemos.forEach((remoteMemo: any) => {
          thunkAPI.dispatch(saveMemoToLocal(Object.assign({}, remoteMemo, { updateStatus: MemoStatus.UPDATED })));
          fetchTagRetry(oidcFetch, remoteMemo.guid, thunkAPI);
          fetchTrainingRetry(oidcFetch, remoteMemo.guid, thunkAPI);
        });
      });
    } else {
      thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: memosResponse.message }));
      setTimeout(() => fetchMemosWithAttachedContentRetry(oidcFetch, thunkAPI), retryInterval);
    }
  });
};

export const fetchAllMemosAsync = createAsyncThunk<number, Function, { state: RootState }>(SYNC_MEMOS, async (oidcFetch, thunkAPI) => {
  let currentTime = new Date().getTime();
  fetchMemosWithAttachedContentRetry(oidcFetch, thunkAPI);

  return currentTime;
});

const fetchTagRetry = (oidcFetch: Function, memoId: string, thunkAPI: any) => {
  var filteredItems = thunkAPI.getState().memo.memos.filter((m: any) => m.guid === memoId && m.stateCode !== 0);
  if (filteredItems.length > 0) {
    var memoLocal = filteredItems[0];
    fetchTag(oidcFetch, memoLocal.guid).then((tagResponse: FetchResponseShape) => {
      if (tagResponse.responseType === HttpResponseType.SUCCESS) {
        tagResponse.content.then((content: any) => {
          thunkAPI.dispatch(saveMemoTagToLocal(content));
          let memo: UIMemoShape = Object.assign({}, memoLocal, {
            syncStateCode: SyncResult.NO_UPDATE,
            tags: content
          });
          thunkAPI.dispatch(saveTagToLocalMapping(memo));
        });
      } else {
        thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: tagResponse.message }));
        fetchTagRetry(oidcFetch, memoId, thunkAPI);
      }
    });
  }
};

export const refreshMemoTagAsync = createAsyncThunk<number, any, { state: RootState }>(UPDATE_MEMO_TAG, async (param, thunkAPI) => {
  let currentTime = new Date().getTime();
  fetchTagRetry(param.oidcFetch, param.guid, thunkAPI);

  return currentTime;
});

const fetchTrainingRetry = (oidcFetch: Function, memoId: string, thunkAPI: any) => {
  var filteredItems = thunkAPI.getState().memo.memos.filter((m: any) => m.guid === memoId && m.stateCode !== 0);
  if (filteredItems.length > 0) {
    var memoLocal = filteredItems[0];
    fetchTraining(oidcFetch, memoLocal.guid).then((trainingResponse: FetchResponseShape) => {
      if (trainingResponse.responseType === HttpResponseType.SUCCESS) {
        trainingResponse.content.then((content: any) => {
          thunkAPI.dispatch(saveMemoTrainingToLocal(content));
        });
      } else {
        thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: trainingResponse.message }));
        fetchTrainingRetry(oidcFetch, memoId, thunkAPI);
      }
    });
  }
};

export const refreshMemoTrainingAsync = createAsyncThunk<number, any, { state: RootState }>(UPDATE_MEMO_TRAINING, async (param, thunkAPI) => {
  let currentTime = new Date().getTime();
  fetchTrainingRetry(param.oidcFetch, param.guid, thunkAPI);

  return currentTime;
});

const fetchRunTrainingRetry = (oidcFetch: Function, memoId: string, thunkAPI: any) => {
  var filteredItems = thunkAPI.getState().memo.memos.filter((m: any) => m.guid === memoId && m.stateCode !== 0);
  if (filteredItems.length > 0) {
    var memoLocal = filteredItems[0];
    let request: TrainingRequestShape = { trainingType: 0, trainingSourceId: memoLocal.guid };
    fetchRunTraining(oidcFetch, request).then((trainingResponse: FetchResponseShape) => {
      if (trainingResponse.responseType === HttpResponseType.SUCCESS) {
        trainingResponse.content.then((content: any) => {
          thunkAPI.dispatch(saveMemoTrainingToLocal(content));
        });
      } else {
        thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: trainingResponse.message }));
        fetchRunTrainingRetry(oidcFetch, memoId, thunkAPI);
      }
    });
  }
};

export const refreshMemoRunTrainingAsync = createAsyncThunk<number, any, { state: RootState }>(UPDATE_MEMO_TAG, async (param, thunkAPI) => {
  let currentTime = new Date().getTime();
  fetchRunTrainingRetry(param.oidcFetch, param.guid, thunkAPI);

  return currentTime;
});

const remoteFullMemoSyncRetry = (oidcFetch: Function, memoId: string, thunkAPI: any) => {
  let memoLocal = findOneWMObjectFromAll(thunkAPI.getState().memo.memos, memoId);
  let memoContentLocal = findOneWMObjectFromAll(thunkAPI.getState().memo.memoContents, memoId);
  remoteMemoContentSync(oidcFetch, memoLocal, memoContentLocal?.content).then((memoSyncResponse: FetchResponseShape) => {
    if (memoSyncResponse.responseType === HttpResponseType.SUCCESS) {
      memoSyncResponse.content.then((content: any) => {
        let updatedMemo = memoLocal;
        if (content.syncResult === -1 && content.syncedMemo) {
          updatedMemo = content.syncedMemo;
          fetchTagRetry(oidcFetch, content.syncedMemo.guid, thunkAPI);
        }
        thunkAPI.dispatch(saveMemoToLocal(Object.assign({}, updatedMemo, { updateStatus: MemoStatus.UPDATED })));
      });
    } else {
      thunkAPI.dispatch(appendMessageAsync({ messageType: MessageType.ERROR, message: memoSyncResponse.message }));
      setTimeout(() => remoteFullMemoSyncRetry(oidcFetch, memoLocal.guid, thunkAPI), 10000);
    }
  });
};

export const createMemoAsync = createAsyncThunk<number, any, { state: RootState }>(CREATE_MEMO, async (param, thunkAPI) => {
  let currentTime = new Date().getTime();

  let memoLocal = Object.assign({}, param.memo, {
    updateStatus: MemoStatus.UPDATING,
    modifyTimestamp: currentTime
  });
  if (memoLocal.createTimestamp === 0) memoLocal.createTimestamp = currentTime;
  thunkAPI.dispatch(createMemoWithContent(memoLocal));
  remoteFullMemoSyncRetry(param.oidcFetch, memoLocal.guid, thunkAPI);

  return currentTime;
});

export const updateMemoAsync = createAsyncThunk<number, any, { state: RootState }>(UPDATE_MEMO, async (param, thunkAPI) => {
  let currentTime = new Date().getTime();

  let memoLocal = Object.assign({}, param.memo, {
    updateStatus: MemoStatus.UPDATING,
    modifyTimestamp: currentTime
  });
  if (memoLocal.createTimestamp === 0) memoLocal.createTimestamp = currentTime;
  thunkAPI.dispatch(saveMemoToLocal(memoLocal));
  remoteFullMemoSyncRetry(param.oidcFetch, memoLocal.guid, thunkAPI);

  return currentTime;
});

export const updateMemoContentAsync = createAsyncThunk<number, any, { state: RootState }>(UPDATE_MEMO_CONTENT, async (param, thunkAPI) => {
  let currentTime = new Date().getTime();

  let memoLocal = findOneWMObjectFromAll(thunkAPI.getState().memo.memos, param.memoId);
  if (memoLocal) {
    let contentChanged = true;
    let memoContentLocal = findOneWMObjectFromAll(thunkAPI.getState().memo.memoContents, param.memoId);
    if (memoContentLocal) {
      if (memoContentLocal.content === param.memoContent) {
        contentChanged = false;
      } else {
        memoContentLocal = Object.assign({}, memoContentLocal, { modifyTimestamp: currentTime, content: param.memoContent });
      }
    } else {
      memoContentLocal = Object.assign(getEmptyMemoContent(), memoLocal, { modifyTimestamp: currentTime, content: param.memoContent });
    }
    if (contentChanged) {
      if (memoContentLocal.createTimestamp === 0) memoContentLocal.createTimestamp = currentTime;
      thunkAPI.dispatch(saveMemoToLocal(Object.assign({}, memoLocal, { modifyTimestamp: currentTime, updateStatus: MemoStatus.UPDATING })));
      thunkAPI.dispatch(saveMemoContentToLocal(memoContentLocal));
      remoteFullMemoSyncRetry(param.oidcFetch, memoContentLocal.guid, thunkAPI);
    }
  }

  return currentTime;
});

export const refreshLocalMemoAsync = createAsyncThunk<number, any, { state: RootState }>(REFRESH_MEMO, async (param, thunkAPI) => {
  let currentTime = new Date().getTime();

  var filteredItems = thunkAPI.getState().memo.memos.filter((m: UIMemoShape) => m.guid === param.guid);
  if (filteredItems.length == 0 || (filteredItems.length > 0 && param.modifyTimestamp > filteredItems[0].modifyTimestamp)) {
    fetchFullMemoRetry(param.oidcFetch, param.guid, thunkAPI);
  }

  return currentTime;
});

const syncFailedMemoUpdate = (memoState: any) => {
  if (memoState.memoContentUpdated && memoState.newContentRecords) {
    if (memoState.newContentRecords.length < 1) {
      memoState.memoContentUpdated = false;
      return;
    }

    let updatedAddedMemos: UIMemoShape[] = memoState.memos;
    memoState.newContentRecords.forEach((newContentRecord: any) => {
      let memo = findOneWMObjectFromAll(memoState.memos, newContentRecord.guid);
      if (memo) {
        updatedAddedMemos = updatedAddedMemos.filter((m: any) => m.guid !== newContentRecord.guid);
        updatedAddedMemos.push(
          Object.assign({}, memo, {
            content: newContentRecord.content,
            modifyTimestamp: newContentRecord.modifyTimestamp
          })
        );
      }
    });
    memoState.memos = updatedAddedMemos.sort((a, b) => a.title.localeCompare(b.title));
    memoState.newContentRecords = [];
    memoState.memoContentUpdated = false;
  }
};

export const updateMemoShareAsync = createAsyncThunk<number, any, { state: RootState }>(UPDATE_MEMO_SHARE, async (param, thunkAPI) => {
  const state = thunkAPI.getState();
  let currentTime = new Date().getTime();

  try {
    let newShare: any;
    let memo = findOneWMObjectFromAll(state.memo.memos, param.shareObject.guid);
    let shareSetting = findOneWMObjectFromAll(state.memo.memoShares, param.shareObject.guid);
    if (shareSetting) {
      newShare = Object.assign({}, shareSetting, param.shareObject.setting, {
        title: memo?.title,
        createTimestamp: currentTime,
        modifyTimestamp: currentTime
      });
    } else {
      newShare = Object.assign({}, param.shareObject.setting, {
        title: memo?.title,
        createTimestamp: currentTime,
        modifyTimestamp: currentTime
      });
    }

    thunkAPI.dispatch(saveEditMemoShareToLocal(Object.assign({}, param.shareObject, { newShare: newShare })));
    remoteMemoShareSyncRetry(param.oidcFetch, newShare.guid, thunkAPI);
  } catch (ex) {
    console.log(ex);
  }
  return currentTime;
});

export const updateMemoPropertiesAsync = createAsyncThunk<number, any, { state: RootState }>(UPDATE_MEMO_PROPERTIES, async (param, thunkAPI) => {
  const state = thunkAPI.getState();
  let currentTime = new Date().getTime();

  let memo = findOneWMObjectFromAll(state.memo.memos, param.memoId);
  if (memo) {
    thunkAPI.dispatch(changeMemoProperties(Object.assign({}, memo, { updateStatus: param.updateStatus })));
  }

  return currentTime;
});

export const memoSlice = createSlice({
  name: 'memo',
  initialState,
  reducers: {
    resetEditMemoContent: (state, action: PayloadAction<string>) => {
      if (!action.payload) {
        state.currentEditMemoContent = '';
        return;
      }
      let memo = findOneWMObjectFromAll(state.memos, action.payload);
      if (memo) {
        let memoContent = findOneWMObjectFromAll(state.memoContents, action.payload);
        if (memoContent) {
          state.currentEditMemoContent = memoContent.content;
        } else {
          state.currentEditMemoContent = '';
        }
      } else {
        state.currentEditMemoContent = '';
      }
    },
    changeSelectedMemoId: (state, action: PayloadAction<string>) => {
      if (state.selectedMemoId !== action.payload) {
        state.selectedMemoId = action.payload;
        let memo = findOneWMObjectFromAll(state.memos, action.payload);
        if (memo) {
          state.currentMemo = Object.assign({}, memo);

          let memoContent = findOneWMObjectFromAll(state.memoContents, action.payload);
          if (memoContent) {
            state.currentMemoContent = Object.assign({}, memoContent);
            state.currentEditMemoContent = memoContent.content;
          } else {
            state.currentMemoContent = undefined;
            state.currentEditMemoContent = '';
          }
        } else {
          state.currentMemo = undefined;
          state.currentMemoContent = undefined;
          state.currentEditMemoContent = '';
        }

        let memoTags = findOneWMObjectFromAll(state.memoTags, action.payload);
        // console.log("memoTags", memoTags.guid);
        if (memoTags) {
          state.currentMemoTags = Object.assign({}, memoTags);
        } else {
          state.currentMemoTags = undefined;
        }

        let memoTrainings = findOneWMObjectFromAll(state.memoTrainings, action.payload);
        if (memoTrainings) {
          state.currentMemoTrainings = Object.assign({}, memoTrainings);
        } else {
          state.currentMemoTrainings = undefined;
        }
      }
    },
    changeMemoProperties: (state, action: PayloadAction<UIMemoShape>) => {
      var filteredItems = state.memos.filter((m) => m.guid !== action.payload.guid);
      var updatedAddedMemos = [...filteredItems, Object.assign({}, action.payload)];
      state.memos = updatedAddedMemos.sort((a, b) => a.title.localeCompare(b.title));

      if (state.selectedMemoId === action.payload.guid) {
        let memo = findOneWMObjectFromAll(state.memos, action.payload.guid);
        state.currentMemo = Object.assign({}, memo);
      }
    },
    createMemoWithContent: (state, action: PayloadAction<UIMemoShape>) => {
      var updatedAddedMemos: any[];
      let memoShare = findOneWMObjectFromAll(state.memoShares, action.payload.guid);
      if (memoShare) {
        updatedAddedMemos = changeMemoShareFlag(state.memos, action.payload as any, memoShare);
      } else {
        var filteredItems = state.memos.filter((m) => m.guid !== action.payload.guid);
        updatedAddedMemos = [...filteredItems, Object.assign({}, action.payload, { hasShare: false })];
      }
      state.memos = updatedAddedMemos.sort((a, b) => a.title.localeCompare(b.title));

      let newMemoContent: UIMemoContentShape = Object.assign(getEmptyMemoContent(), {
        guid: action.payload.guid,
        createTimestamp: action.payload.createTimestamp,
        modifyTimestamp: action.payload.modifyTimestamp
      });
      var filteredContents = state.memoContents.filter((m) => m.guid !== action.payload.guid);
      state.memoContents = [...filteredContents, newMemoContent];

      if (state.selectedMemoId === action.payload.guid) {
        let memo = findOneWMObjectFromAll(state.memos, action.payload.guid);
        state.currentMemo = Object.assign({}, memo);

        state.currentMemoContent = Object.assign({}, newMemoContent);
        state.currentEditMemoContent = newMemoContent.content;
      }
    },
    saveMemoToLocal: (state, action: PayloadAction<UIMemoShape>) => {
      var updatedAddedMemos: any[];
      let memoShare = findOneWMObjectFromAll(state.memoShares, action.payload.guid);
      if (memoShare) {
        updatedAddedMemos = changeMemoShareFlag(state.memos, action.payload as any, memoShare);
      } else {
        var filteredItems = state.memos.filter((m) => m.guid !== action.payload.guid);
        updatedAddedMemos = [...filteredItems, Object.assign({}, action.payload, { hasShare: false })];
      }
      state.memos = updatedAddedMemos.sort((a, b) => a.title.localeCompare(b.title));

      if (state.selectedMemoId === action.payload.guid) {
        let memo = findOneWMObjectFromAll(state.memos, action.payload.guid);
        state.currentMemo = Object.assign({}, memo);
      }
    },
    saveMemoContentToLocal: (state, action: PayloadAction<UIMemoContentShape>) => {
      var filteredItems = state.memoContents.filter((m) => m.guid !== action.payload.guid);
      state.memoContents = [...filteredItems, action.payload];

      if (state.selectedMemoId === action.payload.guid) {
        let memoContent = findOneWMObjectFromAll(state.memoContents, action.payload.guid);
        state.currentMemoContent = Object.assign({}, memoContent);
        state.currentEditMemoContent = memoContent.content;
      }
    },
    saveEditMemoShareToLocal: (state, action: PayloadAction<any>) => {
      let memo = findOneWMObjectFromAll(state.memos, action.payload.guid);
      if (memo) {
        var updatedMemos: any[] = changeMemoShareFlag(state.memos, memo, action.payload.setting);
        state.memos = updatedMemos.sort((a, b) => a.title.localeCompare(b.title));
      }

      var filteredItems = state.memoShares.filter((m: any) => m.guid !== action.payload.guid);
      state.memoShares = [...filteredItems, action.payload.newShare];
    },
    saveRemoteMemoShareToLocal: (state, action: PayloadAction<UIShareStatusShape>) => {
      let memoShare = getEmptyShare();
      let memo = findOneWMObjectFromAll(state.memos, action.payload.guid);
      memoShare.title = memo?.title;
      memoShare.typeMark = SHARE_TYPE_MARK.MEMO;
      memoShare.guid = action.payload.guid;
      memoShare.createTimestamp = action.payload.createTimestamp;
      memoShare.modifyTimestamp = action.payload.modifyTimestamp;
      let targetUserGroupIds: any[] = [];
      let targetUserIds: any[] = [];
      action.payload.shareStatus &&
        action.payload.shareStatus.forEach((shareInfo) => {
          if (shareInfo.targetType === SHARE_SETTING_TYPE_MARK.PUBLIC) memoShare.isPublic = true;
          if (shareInfo.targetType === SHARE_SETTING_TYPE_MARK.USER_GROUP) targetUserGroupIds.push({ id: shareInfo.targetId, label: '' });
          if (shareInfo.targetType === SHARE_SETTING_TYPE_MARK.USER) targetUserIds.push({ id: shareInfo.targetId, label: '' });
        });
      memoShare.targetUserGroupIds = targetUserGroupIds;
      memoShare.targetUserIds = targetUserIds;
      var filteredItems = state.memoShares.filter((m) => m.guid !== action.payload.guid);
      state.memoShares = [...filteredItems, memoShare];

      if (memo) {
        var updatedMemos: any[] = changeMemoShareFlag(state.memos, memo, memoShare);
        state.memos = updatedMemos.sort((a, b) => a.title.localeCompare(b.title));
      }
    },
    saveMemoTagToLocal: (state, action: PayloadAction<UITagListShape>) => {
      var filteredItems = state.memoTags.filter((t) => t.guid !== action.payload.guid);
      state.memoTags = [...filteredItems, action.payload];

      let memoTags = findOneWMObjectFromAll(state.memoTags, state.selectedMemoId);
      if (memoTags) {
        state.currentMemoTags = Object.assign({}, memoTags);
      } else {
        state.currentMemoTags = undefined;
      }
    },
    saveMemoTrainingToLocal: (state, action: PayloadAction<UITrainingListShape>) => {
      var filteredItems = state.memoTrainings.filter((t) => t.guid !== action.payload.guid);
      state.memoTrainings = [...filteredItems, action.payload];

      let memoTrainings = findOneWMObjectFromAll(state.memoTrainings, state.selectedMemoId);
      if (memoTrainings) {
        state.currentMemoTrainings = Object.assign({}, memoTrainings);
      } else {
        state.currentMemoTrainings = undefined;
      }
    },
    saveMemoRepresentationToLocal: (state, action: PayloadAction<UIRepresentationListShape>) => {
      var filteredItems = state.representations.filter((r) => r.guid !== action.payload.guid);
      state.representations = [...filteredItems, action.payload];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(syncMemoShareByGroupAsync.pending, (state) => {
        state.isSyncing = true;
        state.status = 'loading';
      })
      .addCase(syncMemoShareByGroupAsync.fulfilled, (state, action) => {
        state.isSyncing = false;
        state.status = 'idle';
      })
      .addCase(syncMemoShareByGroupAsync.rejected, (state) => {
        console.log('syncMemoShareByGroupAsync.rejected');
        state.isSyncing = false;
        state.status = 'failed';
      })
      .addCase(syncMemoByGroupAsync.pending, (state) => {
        state.isSyncing = true;
        state.status = 'loading';
      })
      .addCase(syncMemoByGroupAsync.fulfilled, (state, action) => {
        state.isSyncing = false;
        state.status = 'idle';
      })
      .addCase(syncMemoByGroupAsync.rejected, (state) => {
        console.log('syncMemoByGroupAsync.rejected');
        state.isSyncing = false;
        state.status = 'failed';
      })
      .addCase(fetchAllMemoSharesAsync.pending, (state) => {
        state.isSyncing = true;
        state.status = 'loading';
      })
      .addCase(fetchAllMemoSharesAsync.fulfilled, (state, action) => {
        state.isSyncing = false;
        state.status = 'idle';
      })
      .addCase(fetchAllMemoSharesAsync.rejected, (state) => {
        console.log('syncAllLocalMemoShareAsync.rejected');
        state.isSyncing = false;
        state.status = 'failed';
      })
      .addCase(fetchAllMemosAsync.pending, (state) => {
        state.isSyncing = true;
        state.status = 'loading';
      })
      .addCase(fetchAllMemosAsync.fulfilled, (state, action) => {
        state.isSyncing = false;
        state.status = 'idle';
      })
      .addCase(fetchAllMemosAsync.rejected, (state) => {
        console.log('syncAllLocalMemoAsync.rejected');
        state.isSyncing = false;
        state.status = 'failed';
      })
      .addCase(refreshMemoTagAsync.pending, (state) => {
        state.isSyncing = true;
        state.status = 'loading';
      })
      .addCase(refreshMemoTagAsync.fulfilled, (state, action) => {
        state.isSyncing = false;
        state.status = 'idle';
      })
      .addCase(refreshMemoTagAsync.rejected, (state) => {
        console.log('updateMemoTagAsync.rejected');
        state.isSyncing = false;
        state.status = 'failed';
      })
      .addCase(refreshMemoTrainingAsync.pending, (state) => {
        state.isSyncing = true;
        state.status = 'loading';
      })
      .addCase(refreshMemoTrainingAsync.fulfilled, (state, action) => {
        state.isSyncing = false;
        state.status = 'idle';
      })
      .addCase(refreshMemoTrainingAsync.rejected, (state) => {
        console.log('updateMemoTrainingAsync.rejected');
        state.isSyncing = false;
        state.status = 'failed';
      })
      .addCase(createMemoAsync.pending, (state) => {
        state.isSyncing = true;
        state.status = 'loading';
      })
      .addCase(createMemoAsync.fulfilled, (state, action) => {
        state.isSyncing = false;
        state.status = 'idle';
      })
      .addCase(createMemoAsync.rejected, (state) => {
        console.log('createMemoAsync.rejected');
        state.isSyncing = false;
        state.status = 'failed';
      })
      .addCase(updateMemoAsync.pending, (state) => {
        state.isSyncing = true;
        state.status = 'loading';
      })
      .addCase(updateMemoAsync.fulfilled, (state, action) => {
        state.isSyncing = false;
        state.status = 'idle';
      })
      .addCase(updateMemoAsync.rejected, (state) => {
        console.log('updateMemoAsync.rejected');
        state.isSyncing = false;
        state.status = 'failed';
      })
      .addCase(updateMemoContentAsync.pending, (state) => {
        state.isSyncing = true;
        state.status = 'loading';
      })
      .addCase(updateMemoContentAsync.fulfilled, (state, action) => {
        state.isSyncing = false;
        state.status = 'idle';
      })
      .addCase(updateMemoContentAsync.rejected, (state) => {
        console.log('updateMemoContentAsync.rejected');
        state.isSyncing = false;
        state.status = 'failed';
      })
      .addCase(refreshLocalMemoAsync.pending, (state) => {
        state.isSyncing = true;
        state.status = 'loading';
      })
      .addCase(refreshLocalMemoAsync.fulfilled, (state, action) => {
        state.isSyncing = false;
        state.status = 'idle';
      })
      .addCase(refreshLocalMemoAsync.rejected, (state) => {
        console.log('refreshLocalMemoAsync.rejected');
        state.isSyncing = false;
        state.status = 'failed';
      })
      .addCase(updateMemoShareAsync.pending, (state) => {
        state.isSyncing = true;
        state.status = 'loading';
      })
      .addCase(updateMemoShareAsync.fulfilled, (state, action) => {
        state.isSyncing = false;
        state.status = 'idle';
      })
      .addCase(updateMemoShareAsync.rejected, (state) => {
        console.log('updateMemoShareAsync.rejected');
        state.isSyncing = false;
        state.status = 'failed';
      })
      .addCase(updateMemoPropertiesAsync.pending, (state) => {
        state.isSyncing = true;
        state.status = 'loading';
      })
      .addCase(updateMemoPropertiesAsync.fulfilled, (state, action) => {
        state.isSyncing = false;
        state.status = 'idle';
      })
      .addCase(updateMemoPropertiesAsync.rejected, (state) => {
        console.log('updateMemoPropertiesAsync.rejected');
        state.isSyncing = false;
        state.status = 'failed';
      })
      .addCase(transferToGroupByGroupIdAsync.pending, (state) => {
        state.isSyncing = true;
        state.status = 'loading';
      })
      .addCase(transferToGroupByGroupIdAsync.fulfilled, (state, action) => {
        state.isSyncing = false;
        state.status = 'idle';
      })
      .addCase(transferToGroupByGroupIdAsync.rejected, (state) => {
        console.log('transferToGroupByGroupIdAsync.rejected', state);
        state.isSyncing = false;
        state.status = 'failed';
      });
  }
});

export const {
  resetEditMemoContent,
  changeSelectedMemoId,
  changeMemoProperties,
  createMemoWithContent,
  saveMemoToLocal,
  saveMemoContentToLocal,
  saveEditMemoShareToLocal,
  saveRemoteMemoShareToLocal,
  saveMemoTagToLocal,
  saveMemoTrainingToLocal,
  saveMemoRepresentationToLocal
} = memoSlice.actions;

export const selectMemos = (state: RootState) => state.memo.memos;
export const selectMemoShares = (state: RootState) => state.memo.memoShares;
export const selectCurrentMemo = (state: RootState) => state.memo.currentMemo;
export const selectCurrentMemoContent = (state: RootState) => state.memo.currentMemoContent;
export const selectCurrentMemoTags = (state: RootState) => state.memo.currentMemoTags;
export const selectCurrentEditMemoContent = (state: RootState) => state.memo.currentEditMemoContent;
export const selectCurrentMemoTrainings = (state: RootState) => state.memo.currentMemoTrainings;

export default memoSlice.reducer;
